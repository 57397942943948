import { Component, OnInit } from '@angular/core';
import { ContentService } from '../common/content.service';

@Component({
  selector: 'app-presentation',
  templateUrl: './presentation.component.html',
  styleUrls: ['./presentation.component.scss']
})

export class PresentationComponent implements OnInit {

  public sections;
  constructor(private contentService: ContentService) { }

  ngOnInit() {
    this.contentService.getSections('5e56498a77374e4de717e875')
      .subscribe(data => {
        this.sections = data;
      });
  }


}
