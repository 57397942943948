import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { SafeHtmlPipe } from './app.pipe';
import { ContentService } from './common/content.service';
import { HeaderComponent } from './header/header.component';
import { InscriptionsComponent } from './inscriptions/inscriptions.component';
import { MediaComponent } from './media/media.component';
import { ParcoursComponent } from './parcours/parcours.component';
import { PresentationComponent } from './presentation/presentation.component';
import { ReglementComponent } from './reglement/reglement.component';
import { SponsorsComponent } from './sponsors/sponsors.component';
import { TodoComponent } from './todo/todo.component';



export const AppRoutes = [
  {
    path: 'presentation',
    component: PresentationComponent
  },
  {
    path: 'parcours',
    component: ParcoursComponent
  },
  {
    path: 'reglement',
    component: ReglementComponent
  },
  {
    path: 'todo',
    component: TodoComponent
  },
  {
    path: 'inscriptions',
    component: InscriptionsComponent
  },
  {
    path: 'sponsors',
    component: SponsorsComponent
  },
  {
    path: 'media',
    component: MediaComponent
  },
  {
    path: '',
    redirectTo: '/presentation',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/presentation',
    pathMatch: 'full'
  }
];


@NgModule({
  declarations: [
    SafeHtmlPipe,
    AppComponent,
    HeaderComponent,
    PresentationComponent,
    ParcoursComponent,
    ReglementComponent,
    TodoComponent,
    SponsorsComponent,
    InscriptionsComponent,
    MediaComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(AppRoutes),
  ],
  providers: [ContentService],
  bootstrap: [AppComponent]
})
export class AppModule { }
