import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';

@Injectable()
export class ContentService implements OnInit {

  public content;
  public key = '3d01a8ecd8d47705fbab1cf640d62548';
  public token = '2863b5d71b434008ce577b7607c3227f291c53c1e822f022a6c2e82e3ed4a4a3';
  public url = 'https://api.trello.com/1/lists/?&key=' + this.key + '&token=' + this.token;
  constructor(private http: HttpClient) { }

  ngOnInit() {
  }

  getSections(listId) {
    const url = 'https://api.trello.com/1/lists/' + listId + '/cards?key=' + this.key + '&token=' + this.token;
    return this.http.get(url);
  }

}
