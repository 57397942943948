import { Component, OnInit } from '@angular/core';
import { ContentService } from '../common/content.service';

@Component({
  selector: 'app-reglement',
  templateUrl: './reglement.component.html',
  styleUrls: ['./reglement.component.scss']
})
export class ReglementComponent implements OnInit {

  public sections;
  constructor(private contentService: ContentService) { }

  ngOnInit() {
    this.contentService.getSections('5e56498a77374e4de717e878')
      .subscribe(data => {
        this.sections = data;
      });
  }

}
